import React from "react";
import Grid from "@mui/material/Grid";
import Project from "../../Components/Project";
import Container from "@mui/material/Container";
import ResponsiveAppBar from "../../Components/ResponsiveAppBar";

import Photos from "./Screenshot 2022-12-17 at 20-12-50 Flowers.png"
import bFloat from "./Screenshot 2022-12-17 at 20-20-27 bFloat16 Visualizer.png"
import wtf from "./Screenshot 2022-12-17 at 20-21-22 WTF.png"
import timeline from "./Screenshot 2022-12-17 at 20-22-50 Timeline.png"
import clip from "./Screenshot 2022-12-17 at 20-23-51 Clip.png"
import bpm from "./Screenshot 2023-07-15 at 18-30-01 BPM Calc.png"
import short from "./Screenshot 2023-07-16 at 22-00-09 Short.png"
import {Helmet} from "react-helmet";

const https = 'https://'
const enzoDev = '.enzobarrett.dev/'

export default function Projects(props) {
    return (
        <>
            <ResponsiveAppBar backgroundColor={props.backgroundColor}/>

            <Helmet>
                <title>Projects</title>
                <meta name="description" content="Enzo's Projects" />
            </Helmet>

            <Container fixed sx={{ mt: 5, mb:5 }}>
                <Grid container spacing={4} justifyContent="flex-start" alignItems="center" sx={{ flexDirection: { xs: "column", sm: "row"} }}>
                    <Grid item xs={12} sm={6} md={4}>
                        <Project
                            {...props}
                            link={https + 'photo' + enzoDev}
                            image={Photos}
                            name={'Photography'}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Project
                            {...props}
                            link={https + 'bfloat16' + enzoDev}
                            image={bFloat}
                            name={'bFloat16 Visualizer'}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Project
                            {...props}
                            link={'https://wtfamidoi.ng'}
                            image={wtf}
                            name={'wtfamidoi.ng'}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Project
                            {...props}
                            link={https + 'timeline' + enzoDev}
                            image={timeline}
                            name={'Timeline Generator'}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Project
                            {...props}
                            link={https + 'clip' + enzoDev}
                            image={clip}
                            name={'Server Clipboard'}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Project
                            {...props}
                            link={https + 'bpm' + enzoDev}
                            image={bpm}
                            name={'BPM Calculator'}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Project
                            {...props}
                            link={https + 'enzob.xyz'}
                            image={short}
                            name={'URL Shortener'}
                        />
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}