import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import React, {useEffect, useMemo, useState} from "react";
import Typography from "@mui/material/Typography";
import {Link} from "react-router-dom";
import {getBlogUrl, getSiteUrl} from "../Constants/Constants";
import Button from "@mui/material/Button";
import PostMarkdown from "../Components/PostMarkdown";
import {Helmet} from "react-helmet";
const { DateTime } = require("luxon");

const siteURL = getSiteUrl()
const blogURL = getBlogUrl()

const shortAPI = 'https://enzob.xyz/';

export default function Post(props) {
    let [text, setText] = useState('')
    let [heading, setHeading] = useState('')
    let [date, setDate] = useState('')
    let [dateString, setDateString] = useState('')
    let [permalink, setPermalink] = useState('')
    let [prevLink, setPrevLink] = useState('')
    let [nextLink, setNextLink] = useState('')
    let [shortened, setShortened] = useState(false)
    let [shortURL, setShortURL] = useState('')

    function shortenUrl() {
        fetch(shortAPI, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "text/plain",
            },
            body: siteURL + permalink, // body data type must match "Content-Type" header
        })
            .then(r => r.text())
            .then(url => {
                setShortURL(url)
                setShortened(true)
            });
    }

    useEffect(() => {
        fetch(props.post)
            .then(res => res.json())
            .then((json) =>
            {
                setText(json['body'])
                setHeading(json['title'])
                setDate(json['date'])
                setDateString(DateTime.fromISO(json['date']).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY))

                const dateArr = json['date'].split('-')

                setPermalink(`/blog/${dateArr[0]}/${dateArr[1]}/${dateArr[2]}`)
            });
    }, [props.post])

    // next link
    useEffect(() => {
        if (date === '')
            return
        const dateArr = date.split('-')

        fetch(blogURL + `/${dateArr[0]}/${dateArr[1]}/${dateArr[2]}/next`)
            .then((res) =>
            {
                if (res.status === 200)
                    return res.json()
                        .then((json) =>
                        {
                            setNextLink(`/blog/${json['year']}/${json['month']}/${json['day']}`)
                        });
            })

        fetch(blogURL + `/${dateArr[0]}/${dateArr[1]}/${dateArr[2]}/prev`)
            .then((res) =>
            {
                if (res.status === 200)
                    return res.json()
                        .then((json) =>
                        {
                            setPrevLink(`/blog/${json['year']}/${json['month']}/${json['day']}`)
                        });
            })
    }, [date])

    function clearLinks() {
        setPrevLink('')
        setNextLink('')
        setShortened(false)
        setShortURL('')
    }

    const markdown = useMemo(() => <PostMarkdown text={text}/>, [text])

    return (
        <div>
            <Helmet>
                <title>{heading}</title>
                <meta name="description" content={dateString} />
            </Helmet>
            <Paper>
                <Container sx={{pt: '25px', pb: '30px', mb: '8vh'}}>

                    <Typography sx={{marginBottom: '5px'}} variant="h4" component="h2">
                        {heading}
                    </Typography>

                    <Typography sx={{fontWeight: '300', marginBottom: '15px'}} variant="h6" component="h2">
                        {dateString}
                    </Typography>

                    {prevLink ?
                        <Link to={prevLink}><Button onClick={clearLinks} sx={{marginRight: '15px'}} variant="contained">Prev
                            Post</Button></Link> : <></>}
                    {nextLink ? <Link to={nextLink}><Button onClick={clearLinks} variant="contained">Next
                        Post</Button></Link> : <></>}

                    <Typography sx={{fontWeight: '400'}} variant="h6" component="div">
                        {permalink ?
                            <p>Permanent link to this post: {shortURL ? <a href={shortURL}>{shortURL}</a> :
                                <Link to={permalink}>{siteURL + permalink}</Link>}
                                <Button variant={'text'} sx={{ml: "10px"}} onClick={shortenUrl}
                                        disabled={shortened}>shorten</Button></p> : <></>}
                    </Typography>

                    <div className={"markdown-body"}>
                        {markdown}
                    </div>
                </Container>
            </Paper>
        </div>
    )
}