import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import ResponsiveAppBar from "../Components/ResponsiveAppBar";

import {Helmet} from "react-helmet";

export default function ErrorPage(props) {
    return (
        <>
            <ResponsiveAppBar backgroundColor={props.backgroundColor}/>

            <Helmet>
                <title>404 - Page Not Found</title>
                <meta name="description" content="Page Not Found"/>
            </Helmet>

            <Container fixed sx={{ mt: 5, mb:5 }}>
                <Typography variant={"h4"}>
                    404 - Page Not Found
                </Typography>
            </Container>
        </>
    )
}