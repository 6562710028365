export const colors = [
    '#949784',
    '#74aabd',
    '#b17113',
    '#2084a8',
    '#BFBEA8',
    '#8C8474',
    '#9CBF4E',
    '#647345',
    '#BF0413',
    '#595959',
    '#93A651',
    '#73020C',
    '#6E8C03',
    '#5F8C4A',
    '#BF5B04',
    '#595959',
    '#A67A46',
    '#222C40',
    '#568EBF',
    '#592B02',
    '#737373',
    '#404040',
    '#595959',
    '#8C8C8C',
    '#A69972',
    '#F28729',
    '#656A73'
]

export function getSiteUrl() {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        return 'http://localhost:3000'
    } else {
        return 'https://enzobarrett.dev'
    }
}

export function getBlogUrl() {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        return 'http://localhost:5000'
    } else {
        return 'https://blog.enzobarrett.dev'
    }
}
