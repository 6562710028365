import './App.css';
import React from "react";
import Home from "./Pages/Home";

import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import Projects from "./Pages/Projects/Projects";
import MaintNotice from "./Components/MaintNotice";

import { colors } from './Constants/Constants'
import Blog from "./Pages/Blog";
import {createTheme, ThemeProvider} from "@mui/material";
import {Helmet} from "react-helmet";
import ErrorPage from "./Pages/ErrorPage";
import Root from "./Root";

const numColors = 27
const backgroundNumber = Math.floor(Math.random() * numColors + 1);
const backgroundColor = colors[backgroundNumber - 1];

const router = createBrowserRouter([
    {
        path: "/",
        errorElement: <ErrorPage/>,
        element: <Root/>,
        children: [
            {
                path: "/",
                element: <Home backgroundNumber={backgroundNumber} backgroundColor={backgroundColor}/>,
            },
            {
                path: "/projects",
                element: <Projects backgroundColor={backgroundColor}/>,
            },
            {
                path: "/blog/:year/:month/:day",
                element: <Blog backgroundColor={backgroundColor}/>,
            },
            {
                path: "/blog",
                element: <Blog backgroundColor={backgroundColor}/>,
            }
        ]
    }
]);

let theme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: backgroundColor,
            contrastText: "#fff" //button text white instead of black
        },
        secondary: {
            main: '#fff',
        },
    },
});

function App() {
    return (
        <MaintNotice>
            <ThemeProvider theme={theme}>
                <Helmet>
                    <title>Enzo Barrett</title>
                    <meta name="description" content="enzobarrett.dev" />
                </Helmet>
                <RouterProvider router={router} />
            </ThemeProvider>
        </MaintNotice>
    );
}

export default App;
