import ReactMarkdown from "react-markdown";
import React from "react";
import rehypeRaw from "rehype-raw";
import {Image} from 'mui-image';
import remarkGfm from "remark-gfm";
import Typography from "@mui/material/Typography";

function renderParagraph(props) {
    const { children } = props;

    if (children && children[0]
        && children.length === 1
        && children[0].props
        && children[0].props.src) { // rendering media without p wrapper

        return children;
    }

    return <p>{children}</p>;
}

export default function PostMarkdown(props) {
    return <ReactMarkdown
        // eslint-disable-next-line
        components={{
            img: ({node, ...props}) => {
                if (props['alt'].includes('vertical'))
                    return (
                        <div>
                            <div className={'vertical'} style={{"--aspect-ratio":"800/1200"}}>
                                <Image {...props} />
                            </div>
                            <Typography component={'div'} variant={'h6'}>{props['alt'].split(' ').slice(1).join(' ')}</Typography>
                        </div>
                    )
                else
                    return (
                        <div>
                            <div style={{"--aspect-ratio":"1200/800"}}>
                                <Image {...props} />
                            </div>
                            <Typography component={'div'} variant={'h6'}>{props['alt']}</Typography>
                        </div>
                    )
            },
            p: renderParagraph
        }}
        rehypePlugins={[rehypeRaw, remarkGfm]}
    >
        {props.text}
    </ReactMarkdown>;
}

