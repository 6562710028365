import React from "react";
import Container from "@mui/material/Container";
import ResponsiveAppBar from "../Components/ResponsiveAppBar";
import "./Blog.css"
import Post from "./Post";
import {useParams} from "react-router-dom";
import {getBlogUrl} from "../Constants/Constants";
import {Helmet} from "react-helmet";

const blogURL = getBlogUrl();

export default function Blog(props) {
    const {year, month, day} = useParams();

    let url = `${blogURL}/latest-post`

    if (year && month && day) {
        url = `${blogURL}/${year}/${month}/${day}`
    }

    return (
        <>
            <ResponsiveAppBar backgroundColor={props.backgroundColor}/>

            <Helmet>
                <title>Blog</title>
                <meta name="description" content="Enzo's Blog" />
            </Helmet>

            <Container className={'blog'}>
                <Post post={url}/>
            </Container>
        </>
    )
}