import {Alert, Snackbar} from "@mui/material";
import {useEffect, useState} from "react";
import FetchAndSet from "../Utils/FetchAndSet";
import {getSiteUrl} from "../Constants/Constants";

export default function MaintNotice(props) {
    const siteURL = getSiteUrl()
    const [open, setOpen] = useState(false);
    const [response, setResponse] = useState('')

    useEffect(() => {
        FetchAndSet(`${siteURL}/maintenance_notice.txt`, setResponse)
    }, [siteURL]);

    useEffect(() => {
        if (response !== '')
            setOpen(true)
    }, [response]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <div>
            <Snackbar open={open} autoHideDuration={3000} anchorOrigin={{vertical: 'top', horizontal: 'center'}} onClose={handleClose}>
                <Alert variant={"filled"} onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    {response}
                </Alert>
            </Snackbar>
            {props.children}
        </div>
    )
}