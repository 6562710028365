import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import * as React from "react";
import Image from "mui-image";

export default function Project(props) {
    return  (
            <Card sx={{ maxWidth: 345 }}>
                <Image src={props.image} duration={400} sx={{borderBottom:`solid 4px ${props.backgroundColor}`}}/>
                <CardContent>
                    <Typography gutterBottom variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
                        {props.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {props.desc}
                    </Typography>
                </CardContent>
                <CardActions>
                    <a
                        href={props.link}
                        target="_blank"
                        rel="noreferrer"
                        className={'text-link'}
                    >
                        <Button size="small" variant={'contained'}>
                            Visit
                        </Button>
                    </a>
                </CardActions>
            </Card>
)
}