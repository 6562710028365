import Image from "mui-image";
import React from "react";
import ResponsiveAppBar from "../Components/ResponsiveAppBar";


export default function Home(props) {

    return (
        <div className="App">
            <ResponsiveAppBar backgroundColor={props.backgroundColor}/>

            <Image src={`/backgrounds/${props.backgroundNumber}.jpg`} duration={500} height={'93vh'} width="100vw" />
        </div>
    )
}